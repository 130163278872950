import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import Text from 'components/Text';
import ContinueButton from './QuizContinueButton';
import { DynamicImage, PrimaryButton } from 'components';
import quizSvgs from 'utils/constants/quizSvgs';
import ChartDes from 'assets/icons/diagram-des.svg';
import ChartMob from 'assets/icons/diagram-mob.svg';
import Checkmark from 'assets/icons/green-checkmark.svg';
import Mark from 'assets/icons/extra-red-mark.svg';

interface PersonalSummaryProps {
  onContinue: () => void;
}

const ImageSection: FC<PersonalSummaryProps> = ({
  data,
  onContinue,
  ...props
}) => {
  const { isTablet, isMobile } = useQuery();
  return (
    <Container {...props}>
      <Title dangerouslySetInnerHTML={{ __html: data?.label }} />
      <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
      <SvgContainer>{isMobile ? <ChartMob /> : <ChartDes />}</SvgContainer>

      <BottomText>{data?.bottomText}</BottomText>
      <ButtonContainer>
        <ContinueButtonStyled onClick={onContinue}>
          {data?.buttonTitle}
        </ContinueButtonStyled>
      </ButtonContainer>
    </Container>
  );
};

export default ImageSection;

const Container = styled.div`
  padding: 2rem 1rem 6rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1rem 1rem 6rem;
  }
`;

const SvgContainer = styled.section`
  width: 100%;
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 0.5rem;
  span {
    color: #dc4b4b;
  }
  @media ${tablet} {
    font-size: 1.5rem;
    padding-bottom: 0.375rem;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem 1.5rem;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background: #fff;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 1.5rem;
  span {
    font-weight: 600;
  }
`;

const BottomText = styled.p`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 1.5rem;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import Text from 'components/Text';
import ContinueButton from './QuizContinueButton';
import { DynamicImage, PrimaryButton } from 'components';
import quizSvgs from 'utils/constants/quizSvgs';
import Chart from 'assets/icons/fat-burnning-chart-results.svg';
import Checkmark from 'assets/icons/green-checkmark.svg';
import Mark from 'assets/icons/extra-red-mark.svg';

interface PersonalSummaryProps {
  onContinue: () => void;
}

const ChartOne: FC<PersonalSummaryProps> = ({ data, onContinue, ...props }) => (
  <Container {...props}>
    <Title dangerouslySetInnerHTML={{ __html: data?.label }} />
    <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
    <ChartStyled />
    <ChartContainer>
      <LeftSide>
        <LeftTitle>
          <Mark /> {data?.nowTitle}
        </LeftTitle>
        <ListBox>
          {data?.nowList.map((item: string, index: number) => (
            <>
              <ListItem key={index}>{item}</ListItem>
              {index < data?.nowList.length - 1 && <Line />}
            </>
          ))}
        </ListBox>
      </LeftSide>

      <RightSide>
        <RightTitle>
          <Checkmark />
          {data?.futureTitle}
        </RightTitle>
        <ListBox>
          {data?.futureList.map((item: string, index: number) => (
            <>
              <ListItem key={index}>{item}</ListItem>
              {index < data?.futureList.length - 1 && <Line />}
            </>
          ))}
        </ListBox>
      </RightSide>
    </ChartContainer>
    <ButtonContainer>
      <ContinueButtonStyled onClick={onContinue}>
        {data?.buttonTitle}
      </ContinueButtonStyled>
    </ButtonContainer>
  </Container>
);

export default ChartOne;

const Container = styled.div`
  padding: 2rem 1rem 6rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1rem 1rem 6rem;
  }
`;

const ChartStyled = styled(Chart)`
  width: 100%;
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 0.5rem;
  span {
    color: #dc4b4b;
  }
  @media ${tablet} {
    font-size: 1.5rem;
    padding-bottom: 0.375rem;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem 1.5rem;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background: #fff;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 1.5rem;
  span {
    font-weight: 600;
  }
`;

const ChartContainer = styled.section`
  display: flex;
  width: 100%;
`;

const LeftSide = styled.section`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  border: 1px solid #dc4b4b;
  border-right: 0;
  background: #fceded;
`;

const RightSide = styled.section`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  border: 1px solid #3fa134;
  border-left: none;
  background: #e1fcda;
`;

const LeftTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  color: #dc4b4b;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const RightTitle = styled(LeftTitle)`
  color: #3fa134;
`;

const ListBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
`;

const ListItem = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const Line = styled.section`
  height: 1px;
  width: 100%;
  background: rgba(119, 119, 119, 0.1);
`;
